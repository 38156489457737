<template>
  <section class="main">
    <div class="header">
        <div class="left">
          <button
            style="background-color: rgb(16, 39, 46)"
            class="left-button"
            @click="ToMap()"
          >
            返回
          </button>
          <button
            style="background-color: rgb(16, 39, 46); margin-left: 10px"
            class="left-button"
            @click="refresh()"
          >
            刷新
          </button>
        </div>
        <p class="title">智 慧 平 台 数 据 驾 驶 舱</p>
        <div class="header-right">
          <div class="time">{{ time }}</div>
          <div class="avatar">
            <el-popover
              placement="top"
              width="160"
              v-model="visible"
              trigger="hover"
            >
              <p style="padding: 10px 0px">确定要退出登录吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="cancelLogout()"
                  >取消</el-button
                >
                <el-button type="primary" size="mini" @click="logout()"
                  >确定</el-button
                >
              </div>
              <!-- <img src="../assets/home/avatar.png" alt="" slot="reference" /> -->
              <img style="width:2.5rem;border-radius: 50%;border-color: black;" src="../assets/home/logo.jpg" alt="" slot="reference" />
            </el-popover>
          </div>
        </div>
      </div>
    <section id="container">
      <div id="loading-animation">
        <i style="font-size: 2rem; color: white" class="el-icon-loading"></i>
      </div>
    </section>
  </section>
</template>
  
<script>
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
let camera, scene, renderer;
// 在页面加载完成后执行
window.onload = function() {
  // 将滚动条置于顶部
  setTimeout(function() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, 100);
}
export default {
  data() {
    return {
      controls:null,
        mesh: null,
        container: null,
        deviceId: "",
        Model: "",
        visible: false,
        // 当前时间
        time: "",
        ModelUrl: "",
        num: 1,
    };
  },
  created() {
      let that = this;
      this.setTime = setInterval(() => {
        that.Date();
      }, 1000);
      this.deviceId = this.$route.query.id;
     
    },
  mounted() {
    this.request3dModel()
  },

  methods: {
    refresh() {
        location.reload();
      },
    init() {
      const container = document.getElementById("container");
      camera = new THREE.PerspectiveCamera(
        75,
        container.clientWidth / container.clientHeight,
        0.1,
        3000
      );
      renderer = new THREE.WebGLRenderer({ antialias: true });

      camera.position.set(0, 0, 80);

      scene = new THREE.Scene();

      renderer.setClearColor(new THREE.Color(0xf7f2f1));
      renderer.setSize(container.clientWidth, container.clientHeight);
      //   开启阻尼
      renderer.shadowMap.enabled = true;
      //   gltf颜色保真
      renderer.outputEncoding = THREE.sRGBEncoding;
      container.appendChild(renderer.domElement);
      // 创建背景纹理
      var textureLoader = new THREE.TextureLoader();
      var backgroundTexture = textureLoader.load(
        "../assets/drive/big-screen-backimage.svg"
      );
      // 设置场景背景
      scene.background = backgroundTexture;
      this.controls = new OrbitControls(camera, renderer.domElement);
      this.controls.target = new THREE.Vector3(0, 0, 0);
      window.addEventListener("resize", this.onWindowResize, false);
      //   this.loadLight();
      this.initLight();
      this.load3D();
    },
    request3dModel() {
        var param = {
          deviceId: this.deviceId,
        };
        this.$post("/device3dPhoto/list", param)
          .then((res) => {
            // 主要参数
            //   console.log(res);
            if (res.code == 1) {
              console.log(res.data);
              if (res.data.length > 0) {
                this.ModelUrl = res.data[0].url;
                this.$nextTick(() => {
                  this.init();
                  this.animate();
                });
              } else {
                this.$message.warning("暂无3d模型");
              }
            } else {
              this.$message.error(res.msg);
            }
          })
          .catch(() => {
            console.log("异常");
          });
      },
    load3D() {
    // 获取用于显示加载动画的元素
      const loadingAnimation = document.getElementById("loading-animation");
      const loader = new GLTFLoader();
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath(
        "https://threejs.org/examples/jsm/libs/draco/"
      );
      dracoLoader.preload();
      loader.setDRACOLoader(dracoLoader);

      loader.load(
        this.ModelUrl,
        (gltf) => {
          scene.add(gltf.scene);
          renderer.render(scene, camera);
          gltf.scene.position.set(-15, -15, 0);
          gltf.scene.scale.set(100, 100, 100);
          gltf.scene.rotation.set(0, 6.3, 0);
          // 隐藏加载动画
          loadingAnimation.style.display = "none";
        },
        (xhr) => {
           // 模型加载过程中的处理逻辑
           if (xhr.lengthComputable) {
            const loadedPercentage = (xhr.loaded / xhr.total) * 100;
            console.log(loadedPercentage + "% loaded");
          }
        },
        (error) => {
          console.error(error);
        }
      );
    },
    loadLight() {
      // 环境光
      const ambient = new THREE.AmbientLight(0xffffff);
      scene.add(ambient);
      const pointLight = new THREE.PointLight(0xffffff, 0.5);
      pointLight.position.set(100, 200, 500);
      pointLight.color.setHSL(255, 255, 255);
      scene.add(pointLight);
    },
    onWindowResize() {
     camera.aspect = window.innerWidth / window.innerHeight;
      renderer.setSize(window.innerWidth, window.innerHeight);
      camera.updateProjectionMatrix();
    },
    initLight() {
      // 添加灯光, 四面八方的灯光
      //即从上方前方照射向场景中心。（ 前面）
      const light1 = new THREE.DirectionalLight(0xeeeeee, 0.9);
      light1.position.set(0, 10, 10);
      scene.add(light1);
      // 即从后方照射向场景中心。 后面
      const light2 = new THREE.DirectionalLight(0xffffff, 1);
      light2.position.set(0, 0, -10);
      scene.add(light2);
      //即从右侧照射向场景中心。 右面
      const light3 = new THREE.DirectionalLight(0xffffff, 1);
      light3.position.set(10, 0, 0);
      scene.add(light3);
      // 即从左侧照射向场景中心。左面
      const light4 = new THREE.DirectionalLight(0xffffff, 1);
      light4.position.set(-10, 0, 0);
      scene.add(light4);
      // 即从上方垂直向下照射。
      const light5 = new THREE.DirectionalLight(0xffffff, 1);
      light5.position.set(0, 10, 0);
      scene.add(light5);
      // 即从右上方前方照射向场景中心。
      const light6 = new THREE.DirectionalLight(0xffffff, 0.3);
      light6.position.set(5, 10, 0);
      scene.add(light6);
      // 即从上方右侧照射向场景中心。
      const light7 = new THREE.DirectionalLight(0xffffff, 0.3);
      light7.position.set(0, 10, 5);
      scene.add(light7);
      // 即从上方左侧照射向场景中心。
      const light8 = new THREE.DirectionalLight(0xffffff, 0.3);
      light8.position.set(0, 10, -5);
      scene.add(light8);
      // 即从左上方前方照射向场景中心
      const light9 = new THREE.DirectionalLight(0xffffff, 0.3);
      light9.position.set(-5, 10, 0);
      scene.add(light9);
      // 下方
      const light10 = new THREE.DirectionalLight(0xffffff, 1);
      light10.position.set(0, -10, 0);
      light10.target.position.set(0, 0, 0); // 设置照射方向指向场景中心
      scene.add(light10);
    },
    animate() {
      requestAnimationFrame(this.animate);
      renderer.render(scene, camera);
    },
    // 返回按钮
    ToMap() {
        this.$router.push("/Citydevice");
      },
      // 获取时间戳
      Date() {
        const nowDate = new Date();
        const date = {
          year: nowDate.getFullYear(),
          month: nowDate.getMonth() + 1,
          date: nowDate.getDate(),
          hours: nowDate.getHours(),
          minutes: nowDate.getMinutes(),
          seconds: nowDate.getSeconds(),
        };
        const newmonth = date.month >= 10 ? date.month : "0" + date.month;
        const newday = date.date >= 10 ? date.date : "0" + date.date;
        const newHours = date.hours >= 10 ? date.hours : "0" + date.hours;
        const newminutes = date.minutes >= 10 ? date.minutes : "0" + date.minutes;
        const newseconds = date.seconds >= 10 ? date.seconds : "0" + date.seconds;
  
        this.time =
          date.year +
          "年" +
          newmonth +
          "月" +
          newday +
          "日" +
          newHours +
          ":" +
          newminutes +
          ":" +
          newseconds;
      },
     
      // 退出登录
      logout() {
        //   window.sessionStorage.clear();
        //   this.clearCookie();
        this.$get("/user/logout")
          .then((res) => {
            if (res.code == 1) {
              this.$router.push("/Login");
              this.$message({
                type: "success",
                message: "登出成功!",
              });
            } else {
              this.$message.error(res.msg);
            }
          })
          .catch(() => {
            console.log("异常");
          });
      },
      // 退出登录取消
      cancelLogout() {
        this.visible = false;
        this.$message({
          type: "info",
          message: "已取消登出",
        });
      },
  },
  beforeDestroy() {
      // 清除定时器
      if (this.setTime) {
        clearInterval(this.setTime);
      }
    },
};
</script>
  

  
  
  

<style scoped lang="scss">
.page {
  position: relative;
}

.nav {
  position: absolute;
  top: 20px;
  right: 20px;
}
.main {
    background: url("../assets/drive/big-screen-backimage.svg") center no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    // overflow: hidden;
   
  }
   //   头部
   .header {
    display: flex;
  
    .left {
      position: absolute;
      top: 3.5vh;
      left: 1.563vw;
      display: flex;
      align-items: center;
  
      .left-button {
        display: inline-block;
        height: 4vh;
        line-height: 4vh;
        border: 1px solid rgb(11, 166, 167);
        box-shadow: inset 0px 1px 17px rgb(11, 166, 167);
        // background: #000;
        color: rgb(195, 226, 249);
        font-size: 1.04vw;
        border-radius: 7px;
        padding: 0 0.52vw;
        white-space: nowrap;
        box-sizing: border-box;
      }
    }
  
    .title {
      width: 100%;
      text-align: center;
      // margin-left: -0.5vw;
      background: linear-gradient(
        180deg,
        rgba(238, 238, 238, 1),
        rgba(113, 244, 236, 1)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      // text-fill-color: transparent;
      font-family: PingFang SC;
      font-size: 2.4vw;
      cursor: pointer;
    }
  
    .header-right {
      position: absolute;
      right: 1.563vw;
      top: 3.5vh;
      width: 25%;
      display: flex;
      align-items: center;
  
      .time {
        // margin-left: 20px;
        width: 80vw;
        text-align: right;
        color: rgba(223, 227, 230, 0.952);
        font-family: PingFang;
        font-size: 1.04vw;
        line-height: 26px;
        letter-spacing: 0px;
        cursor: pointer;
      }
  
      .avatar {
        margin: 0 0 0 15px;
        width: 10vw;
      }
    }
  }
#container {
  width: 100%;
  height: 80vh;
  margin: 0;
  overflow: hidden;
  // background: url("../assets/drive/big-screen-backimage.svg") center no-repeat;
  background-size: cover;
  position: absolute;
  top: 20vh;
}
#loading-animation {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>

